import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import moment from 'moment';
import { useInterval } from 'jsx/lib/hooks';
import Icon from 'jsx/components/core/icons/Icon';
import { authenticated, logout, refresh } from '../actions';

const EXPIRY_WARNING = 60 * 2 * 1000; // 2 minutes

/**
 * Polls the expiry token and displays a modal if the token is about to expire.
 * @param {isAuthenticated} isAuthenticated - Whether the user is authenticated or not
 * @returns
 */
const SessionRefresh = ({ isAuthenticated }) => {
  const dispatch = useDispatch();
  const [expiryTime, setExpiryTime] = React.useState('');
  const [expiryWarning, setExpiryWarning] = React.useState(false);
  const [isExpired, setIsExpired] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  useInterval(
    () => {
      if (isRefreshing) return;

      const expiryToken = parseInt(sessionStorage.getItem('expiry'), 10);

      if (Date.now() + EXPIRY_WARNING >= expiryToken) {
        setExpiryWarning(true);
      }
      if (Date.now() >= expiryToken) {
        setIsExpired(true);
      }
      setExpiryTime(moment(expiryToken).fromNow());
    },
    isAuthenticated && !isRefreshing ? 2000 : null,
  );

  const onLogout = () => {
    setExpiryWarning(false);
    setIsExpired(false);

    dispatch(authenticated(false));
    dispatch(logout());
  };

  const onLogin = () => {
    setExpiryWarning(false);
    setIsExpired(false);

    // This component sits outside of the router, so we need to use window.location.replace
    window.location.replace('/login');
  };

  const onContinueSession = async () => {
    /* There is a possibility the next poll will happen before the refresh is complete
     * so we set a flag to prevent the modal from being displayed while refreshing
     */
    setIsRefreshing(true);
    await dispatch(refresh());

    setExpiryWarning(false);
    setIsExpired(false);
    setIsRefreshing(false);
  };

  return (
    <Modal isOpen={isAuthenticated && expiryWarning}>
      <ModalHeader className="bg-corporate text-white">
        <Icon size="1x" name="info-circle" className="mr-2" />
        {!isExpired && 'Your session is about to expire'}
        {isExpired && 'Your session has expired'}
      </ModalHeader>
      <ModalBody>
        {!isExpired &&
          `Your session will expire ${expiryTime}. You will be logged out if you choose not to continue your current session.`}
        {isExpired && 'Your session has expired. Please login to continue.'}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <div>
          {!isExpired && (
            <>
              <Button size="sm" color="light" className="mr-2" onClick={onLogout}>
                Log out
              </Button>
              <Button size="sm" color="success" onClick={onContinueSession}>
                Continue session
              </Button>
            </>
          )}
          {isExpired && (
            <Button size="sm" color="success" onClick={onLogin}>
              Go to login
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SessionRefresh;
